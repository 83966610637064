<script lang="ts">
    import type SocialIconProps from '@/Icons/social_icons/props.ts';

    export let className: SocialIconProps['className'] = '';
</script>

<svg class="text-current {className}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
    ><path
        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
        fill-rule="evenodd"
    ></path></svg
>
